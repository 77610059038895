import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainLayout,
    meta: {
      component: 'MainView'
    }
  },
  {
    path: '/login',
    name: 'LoginView',
    component: MainLayout,
    meta: {
      component: 'LoginView'
    }
  },
  {
    path: '/students',
    name: 'StudentsView',
    component: MainLayout,
    meta: {
      component: 'StudentsView'
    }
  },
  {
    path: '/groups',
    name: 'GroupsView',
    component: MainLayout,
    meta: {
      component: 'GroupsView'
    }
  },
  {
    path: '/trainings',
    name: 'TrainingsView',
    component: MainLayout,
    meta: {
      component: 'TrainingsView'
    }
  },
  {
    path: '/finance',
    name: 'FinanceView',
    component: MainLayout,
    meta: {
      component: 'FinanceView'
    }
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: MainLayout,
    meta: {
      component: 'SettingsView'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
