<template>
    <div class="w-full bg-[#FA1367] z-10 absolute">
        <div class="flex mx-4 justify-between">
            <div class="text-2xl text-white font-bold inline-block">{{ store.trainer.name + ' ' + store.trainer.surname }}</div>
            <div class="flex justify-end w-[30%]">
                <div class="flex" >
                    <div class="flex text-white items-center align-middle">
                        <img src="../../assets/logo.svg" class="w-[30px]">
                        <div class="mt-[-2px]">
                            |
                        </div>
                        <svg class="ml-2" width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 2C0 0.89543 0.895431 0 2 0H18.7692C19.8738 0 20.7692 0.895431 20.7692 2V7.8H0V2Z" fill="#A1D9F7"/>
                            <path d="M0 7.79999H20.7692V13C20.7692 14.1046 19.8738 15 18.7692 15H2C0.895431 15 0 14.1046 0 13V7.79999Z" fill="#E6EE5B"/>
                        </svg>
                    </div>
                    <div>
                        <div @click="$router.push('/all-messages')" class="relative top-[6px] ml-[20px]">
                            <img src="@/mobileApp/assets/bell_icon.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex overflow-x-auto whitespace-nowrap mx-2 mb-1">
            <div v-for="group in store.trainer_groups" @click="selectGroup(group)" class="bg-[#CD0F54] bg-opacity-[0.5] border-[1px] border-[#CD0F54] border-opacity-[0.4] px-[15px] rounded-[6px] py-[4px] mt-[2px] text-white text-center inline-block mr-3">
                <div class="text-[9px] font-light leading-[13px]">
                    група
                </div>
                <div class="font-bold leading-[16px]">
                    {{ group.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../../stores/mainStore'

    export default {
        name: "trainer-data",

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const selectGroup = (group) => {
                store.currentGroup = group
                router.push('/group-view')
            }

            return {
                store,
                selectGroup
            }
        }
    }
</script>
