<template>
    <div>
        <MenuBar />

        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[60px]">
            <div class="text-white">
                <span class="text-[28px] font-semibold">{{ 0 }}</span> активних
            </div>
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ 0 }}</span> боржників
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getStudents } from '@/desktopApp/services/apiRequests'
    import { useMainStore } from '../../stores/mainStore'
    import MenuBar from './components/MenuBar.vue'

    export default {
        name: 'StudentsView',

        setup() {
            const store = useMainStore()

            onMounted(async () => {
                const trainerGroupsIds = store.trainer_groups.map(group => group.id)
                const res = await getStudents(20, 0, trainerGroupsIds)
                console.log('trainerGroupsIds ------- ', res)
            })

        },

        components: {
            MenuBar
        }
    }
</script>

<style lang="scss" scoped>

</style>