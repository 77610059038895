<template>
    <div v-if="isOptionsShow" @click="isOptionsShow = false" class="absolute w-full h-full top-0 left-0"></div>
    <div class="items-center mt-[32px] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] px-1 py-[9.25px] w-[90%] mx-auto flex justify-between">
        <div class="text-base text-[#8C8C8C] pl-[4px]">включити повторюваність подій</div>
        <input @click="changeRepeate" type="checkbox" class="cursor-pointer mr-[3px]">
    </div>
    <div 
        :class="{
            'opacity-70 cursor-not-allowed': isDisabled,
            'cursor-pointer': !isDisabled
        }" 
        class="items-center mt-[32px] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] px-1 py-[9.25px] w-[90%] mx-auto flex justify-between"
        @click="isOptionsShow = !isOptionsShow"
    >
        <div class="text-[#8C8C8C] text-base pl-[4px]">
            оберіть дні повторень
        </div>
        <div class="mt-[5px] mr-[3px]">
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6.29289 6.29289C6.62623 6.62623 6.79289 6.79289 7 6.79289C7.20711 6.79289 7.37377 6.62623 7.70711 6.29289L13 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        </div>
    </div>
    <div v-if="isOptionsShow && !isDisabled" class="bg-[#fff] z-[130] absolute w-[90%] left-[50%] translate-x-[-50%]">
        <div v-for="day in everyCurrentDayOptions" class="flex bg-[#fff] text-[#000] justify-between px-2 py-1">
            {{ day.text }}
            <input type="checkbox" @change="updateOption(day)" :checked="day.selected" class="cursor-pointer">
        </div>
    </div>

</template>

<script>
    import { ref } from 'vue'

    export default {
        name: 'multi-select',

        setup(props, { emit }) {
            const isDisabled = ref(true)
            const isOptionsShow = ref(false)

            const everyCurrentDayOptions = ref([
                { value: 1, text: 'Кожен понеділок', selected: false },
                { value: 2, text: 'Кожен вівторок', selected: false },
                { value: 3, text: 'Кожну середу', selected: false },
                { value: 4, text: 'Кожен четвер', selected: false },
                { value: 5, text: 'Кожну п\'ятницю', selected: false },
                { value: 6, text: 'Кожну суботу', selected: false },
                { value: 7, text: 'Кожну неділю', selected: false }
            ])

            const updateOption = (dayOption) => {
                everyCurrentDayOptions.value.find((day) => {
                    return day.value === dayOption.value
                }).selected = !dayOption.selected
                emit('update', everyCurrentDayOptions.value)
            }

            const changeRepeate = () => {
                if(!isDisabled.value) {
                    emit('enable')
                } else {
                    emit('disable')
                }
                isDisabled.value = !isDisabled.value
            }

            return {
                isDisabled,
                everyCurrentDayOptions,
                isOptionsShow,
                updateOption,
                changeRepeate
            }
        }
    }
</script>
