<template>
    <div>
        <NewTrainingForm class="overflow-y-auto pb-[20px]" v-if="newTrainingFlag" @close="newTrainingFlag = false" :selectedDate="selectedDate" @updateTrainings="selectedDate = null; dateSelectedFlag = false"/>
        <UpdateTrainingForm class="overflow-y-auto pb-[20px]" v-if="updateTrainingsFlag" :selectedTraining="selectedTraining" @close="updateTrainingsFlag = false" @updateTrainings="selectedDate = null; dateSelectedFlag = false"/>
        <div @click="$router.push('/')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#A1D9F7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>
        <TrainingCalendar @settedDay="date => selectDateToNewTraining(date)" class="mt-[20px]"/>

        <div v-if="dateSelectedFlag">
            <div class="text-[#A1D9F7] font-medium w-[60%] mx-auto text-center mt-[20px]">{{ formattedDate }}</div>
            <div>
                <div class="text-[#A1D9F7] font-medium w-[60%] mx-auto text-center mt-[20px]">Виберіть час тренування</div>
                <div class="flex flex-col items-center w-full mt-[20px] mb-[20px]">
                    <button v-for="time in finalTimeRanges" class="w-[90%] mx-auto">
                        <div v-if="time.status != 'free_time'" @click="updateTrainingsFlag = true; selectedTraining = time.training_id" class="bg-[#A1D9F7] mb-2 rounded-[9px] text-[#12214C] px-3 py-2 w-full text-base flex items-center align-middle justify-between mx-auto">
                            <div class="w-[20%]">{{ time.start_time }}</div>
                            <div class="w-[40%] font-semibold truncate">{{ time.group_name }}</div>
                            <div class="w-[30%] ">{{ time.location_name }}</div>
                        </div>
                        <div v-else @click="newTrainingFlag = true" class="bg-[#A1D9F7] bg-opacity-60 border-[1px] border-[#A1D9F7] mb-2 px-3 py-5 rounded-[9px] flex justify-between items-center align-middle">
                            <div class="w-[20%] align-middle mt-[5px]">{{ time.start_time }}</div>
                            <div class="w-[60%] text-center text-[21px] align-middle">+</div>
                            <div class="w-[20%] align-middle mt-[5px]">{{ time.end_time }}</div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div v-else-if="!trainingsLoading" class="text-[#A1D9F7] font-medium w-[60%] mx-auto text-center mt-[20px] opacity-75">
            Виберіть день для нового тренування
        </div>
        <div v-if="trainingsLoading" class="bg-[#12214C] z-50 fixed w-full h-full">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#FA1367] border-t-transparent absolute top-[20px] left-[50%] ml-[-20px]"></div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed } from 'vue'
    import { getPlanedTrainingsByDate } from '../services/apiRequests'
    import TrainingCalendar from '../components/ui/TrainingCalendar/TrainingCalendar.vue'
    import NewTrainingForm from '../components/NewTraining/NewTrainingForm.vue'
    import UpdateTrainingForm from '../components/NewTraining/UpdateTrainingForm.vue'

    export default {
        name: 'AddNewTraining',

        setup() {
            const selectedDate = ref(null)
            const finalTimeRanges = ref([])
            const dateSelectedFlag = ref(false)
            const newTrainingFlag = ref(false)
            const updateTrainingsFlag = ref(false)
            const selectedTraining = ref(null)

            const trainingsLoading = ref(false)

            const formattedDate = computed(() => {
                if (selectedDate.value) {
                    const date = new Date(selectedDate.value)
                    const day = date.getDate()
                    const month = date.getMonth() + 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                    const year = date.getFullYear()

                    return `${day}.${month}.${year}`
                }
            })

            const selectDateToNewTraining = async (date) => {
                trainingsLoading.value = true
                selectedDate.value = date
                dateSelectedFlag.value = false
                const res = await getPlanedTrainingsByDate(date)
                finalTimeRanges.value = generateTimeRanges(res.data.map(training => {
                    return {
                        start_time: training.start_time,
                        end_time: training.end_time,
                        group_name: training.group_label,
                        location_name: training.location_label,
                        training_id: training.training_id
                    }
                }))
                dateSelectedFlag.value = true
                trainingsLoading.value = false
            }

            const generateTimeRanges = (trainings) => {
                const timeRanges = [];
                
                let currentStartTime = '00:00'

                trainings.forEach(training => {
                    timeRanges.push({
                        start_time: currentStartTime,
                        end_time: training.start_time,
                        status: 'free_time'
                    })
                    timeRanges.push({
                        start_time: training.start_time,
                        end_time: training.end_time,
                        status: 'training',
                        group_name: training.group_name,
                        location_name: training.location_name,
                        training_id: training.training_id
                    })
                    currentStartTime = training.end_time
                })
                timeRanges.push({
                    start_time: currentStartTime,
                    end_time: '00:00',
                    status: 'free_time'
                })
                return timeRanges;
            }

            return {
                selectedDate,
                formattedDate,
                selectDateToNewTraining,
                finalTimeRanges,
                dateSelectedFlag,
                newTrainingFlag,
                updateTrainingsFlag,
                selectedTraining,
                trainingsLoading
            }
        },

        components: {
            TrainingCalendar,
            NewTrainingForm,
            UpdateTrainingForm
        }
    }
</script>

<style lang="scss" scoped></style>