<template>
    <div class="bg-[#12214C] fixed h-full w-full mt-[-10px]">
        <div @click="$emit('close')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#A1D9F7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>
        <div v-if="loadingSpinnerFlag" class="bg-[#12214C] fixed w-full h-full">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#FA1367] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
        </div>
        <div v-else>

            <div class="border-b-[1px] border-white pb-[10px] w-[90%] mx-auto text-center font-semibold text-white mt-[10px]">
                РЕДАГУВАННЯ ТРЕНУВАННЯ
            </div>
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Тип тренування
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex justify-center relative">
                            <select v-model="trainingType" class="outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]">
                                <option value="none" class="bg-[#474849] bg-opacity-20">Не задано</option>
                                <option value="training" class="bg-[#474849] bg-opacity-20">Тренування</option>
                                <option value="gym" class="bg-[#474849] bg-opacity-20">Спортзал</option>
                                <option value="competition" class="bg-[#474849] bg-opacity-20">Змагання</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Дата
                    </div> -->
                    <div class="flex justify-center items-center">
                        <input v-model="currentTraining.date" class="custom-date-input bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[9px] py-1 px-2 w-[90%] " type="date">
                    </div>
                </div>
            </div>
            <div class="mt-[32px]">
                <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                    Час тренування
                </div> -->
                <div class="text-center mb-2 text-[#A1D9F7] text-[12px] font-light">
                    оберіть час тренування (початок - кінець)
                </div>
                <div class="flex justify-center items-center">
                    <input v-model="currentTraining.start_time" class="custom-time-input bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[9px] p-1" type="time">
                    <span class="font-bold mx-2 text-[#A1D9F7] text-[20px]">></span>
                    <input v-model="currentTraining.end_time" class="custom-time-input bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[9px] p-1" type="time">
                </div>
            </div>
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Група
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex flex-col items-center relative">
                            <input
                                @change="changeGroupText($event.target.value)"
                                :value="groupText"
                                @focus="groupOptionsShow = true"
                                @blur="groupOptionsShow = false" 
                                class="outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]" 
                                type="text"
                                placeholder="оберіть групу зі списку"
                            />
                            <div v-if="groupOptionsShow" class="absolute top-[36px] bg-[#A1D9F7] z-30 w-[85%]">
                                <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 py-2 bg-[#A1D9F7] bg-opacity-40 cursor-pointer">{{groupOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Локація
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex justify-center relative">
                            <input
                                @change="changeLocationText($event.target.value)"
                                :value="locationText"
                                @focus="locationOptionsShow = true"
                                @blur="locationOptionsShow = false" 
                                class="outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]" 
                                type="text"
                                placeholder="оберіть локацію зі списку"
                            />
                            <div v-if="locationOptionsShow" class="absolute top-[36px] bg-[#A1D9F7] z-30 w-[85%]">
                                <div v-for="locationOption in locationVisibleOptions" @mousedown="setLocation(locationOption)" class="w-full px-1 py-2 bg-[#A1D9F7] bg-opacity-40 cursor-pointer">{{locationOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Коментар
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex flex-col items-center relative">
                            <textarea
                                v-model="description"
                                class="outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]" 
                                type="text"
                                rows="4"
                                placeholder="коментар до події"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
    
            <button @click="updateTraining" class="bg-[#41BF74] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-10 mx-auto">
                <div class="w-full">Змінити тренування</div>
            </button>
            <button @click="deleteTraining" class="bg-[#FA1367] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-2 mx-auto">
                <div class="w-full">Видалити тренування</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed, onBeforeMount } from 'vue'
    import { getAllLocations, getAllGroups, deleteTrainingById, updateTrainingById, getTrainingById } from '../../services/apiRequests'

    export default {
        name: 'UpdateTrainingForm',

        props: {
            selectedTraining: {
                type: String,
                default: ''
            },
        },

        setup(props, { emit }) {
            const group = ref(null)
            const groupText = ref('')
            
            const location = ref(null)
            const locationText = ref('')

            const groupOptions = ref([])
            const locationOptions = ref([])

            const currentTraining = ref({})
            const trainingType = ref('none')
            const description = ref('')

            const loadingSpinnerFlag = ref(true)

            onBeforeMount(async () => {
                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data

                const resLocations = await getAllLocations()
                locationOptions.value = resLocations.data

                const trainingRes = await getTrainingById(props.selectedTraining)
                currentTraining.value = trainingRes.data
                console.log('currentTraining.value', currentTraining.value)
                currentTraining.value.date = currentTraining.value.date.split('T')[0].split('-').join('-')
                trainingType.value = currentTraining.value.type ?? 'none'

                groupText.value = currentTraining.value.group_label
                locationText.value = currentTraining.value.location_label

                description.value = currentTraining.value.description

                group.value = groupOptions.value.find(group => group.name == groupText.value)
                console.log('locationOptions.value ---------- ', locationOptions.value)
                location.value = locationOptions.value.find(location => location.small_name == locationText.value)
                loadingSpinnerFlag.value = false
            })

            const groupVisibleOptions = computed(() => {
                return groupOptions.value.filter(option => option.name.toLowerCase().includes(groupText.value.toLowerCase()) || groupText.value === '')
            })

            const locationVisibleOptions = computed(() => {
                return locationOptions.value.filter(option => option.name.toLowerCase().includes(locationText.value.toLowerCase()) || locationText.value === '')
            })

            const changeGroupText = (groupName) => {
                groupText.value = groupName
                group.value = groupVisibleOptions.value[0]
            }

            const changeLocationText = (locationName) => {
                locationText.value = locationName
                location.value = locationVisibleOptions.value[0]
            }

            const setLocation = (locationOption) => {
                location.value = locationOption
                locationText.value = locationOption.name
                locationOptionsShow.value = false
            }

            const setGroup = (groupOption) => {
                group.value = groupOption;
                groupText.value = groupOption.name;
                groupOptionsShow.value = false;
            }

            const locationOptionsShow = ref(false)
            const groupOptionsShow = ref(false)

            const trainingTime = ref({
                start: '00:00',
                end: '00:00'
            })

            const deleteTraining = async () => {
                loadingSpinnerFlag.value = true
                deleteTrainingById(props.selectedTraining).then(res => {
                    console.log('res ----- ', res)
                    emit('updateTrainings')
                    emit('close')
                    loadingSpinnerFlag.value = false
                })
            }

            const updateTraining = async () => {
                loadingSpinnerFlag.value = true
                trainingType.value = trainingType.value == 'none' ? null : trainingType.value
                console.log('location.value ------- ', location.value)
                updateTrainingById(
                    props.selectedTraining,
                    currentTraining.value.start_time, 
                    currentTraining.value.end_time,
                    currentTraining.value.date, 
                    group.value.group_id, 
                    group.value.name, 
                    location.value.location_id,
                    location.value.small_name,
                    trainingType.value,
                    description.value
                ).then(res => {
                    emit('updateTrainings')
                    emit('close')
                    loadingSpinnerFlag.value = false
                })
            }

            return { 
                group, 
                groupText, 
                groupOptions, 
                groupVisibleOptions, 
                changeGroupText, 
                groupOptionsShow,
                setGroup,
                location,
                locationText,
                locationOptions,
                locationVisibleOptions,
                changeLocationText,
                locationOptionsShow,
                setLocation,
                trainingTime,
                currentTraining,
                deleteTraining,
                updateTraining,
                trainingType,
                description,
                loadingSpinnerFlag
            }
        }
    }
</script>


<style lang="scss" scoped> 
.custom-date-input::-webkit-calendar-picker-indicator {
    background-image: url('../../assets/white_calendar.svg');
    cursor: pointer;
}

.custom-time-input::-webkit-calendar-picker-indicator {
    background-image: url('../../assets/clock_icon.svg');
    cursor: pointer;
}
</style>