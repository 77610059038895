<template>
    <div>
        Finance
    </div>
</template>

<script>
    export default {
        name: 'FinanceView',

        setup() {

        }
    }
</script>

<style lang="scss" scoped>

</style>