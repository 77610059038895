<template>
    <div >
        <div v-if="currentComponent != 'LoginView' && currentComponent != 'Loading'">
            <div class="flex">
                <Sidebar v-if="windowWidth > 1500" class="w-[258px] fixed" />
                <MinSidebar v-else class="w-[100px] fixed z-[250]"/>
                <div v-if="windowWidth > 1500" class="w-[278px]"></div>
                <div v-if="windowWidth <= 1500" class="w-[100px]"></div>
                <div class="w-full">
                    <Header :key="headerKey"/>
                    <component :is="currentComponent"></component>
                </div>
            </div>
        </div>
        <div v-else>
            <component :is="currentComponent"></component>
        </div>
    </div>
</template>

<script>
    import MainView from '../views/MainView.vue'
    import LoginView from '../views/LoginView.vue'
    import Sidebar from '../components/Sidebar.vue'
    import MinSidebar from '../components/MinSidebar.vue'
    import Header from '../components/Header.vue'

    import StudentsView from '../views/StudentsView/StudentsView.vue'
    import GroupsView from '../views/GroupsView.vue'
    import FinanceView from '../views/FinanceView.vue'
    import TrainingsView from '../views/TrainingsView.vue'
    import SettingsView from '../views/SettingsView.vue'

    import { uuid } from 'vue3-uuid'
    import { ref } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { onMounted, onUpdated } from 'vue'

    export default {
        name: 'MainLayout',
        
        setup() {
            const currentComponent = ref('LoginView')
            const router = useRouter()
            const route = useRoute()
            const windowWidth = ref(window.innerWidth)
            const headerKey = ref(uuid.v4())

            onMounted(async () => {
                if (localStorage.access_token && localStorage.refresh_token) {                    
                    currentComponent.value = route.meta.component
                } else {
                    currentComponent.value = 'LoginView'
                }

                window.addEventListener('resize', () => {
                    windowWidth.value = window.innerWidth
                })
            })

            onUpdated(() => {
                if (localStorage.access_token && localStorage.refresh_token) {
                    currentComponent.value = route.meta.component
                    headerKey.value = uuid.v4()
                }
            })

            return {
                currentComponent,
                windowWidth,
                headerKey
            }
        },

        components: {
            MainView,
            LoginView,
            MinSidebar,
            Sidebar,
            StudentsView,
            GroupsView,
            FinanceView,
            TrainingsView,
            SettingsView,
            Header
        }
    }
</script>