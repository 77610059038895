<template>
  <div>
    <div class="flex justify-center items-center">
      <button @click="previousMonth" class="mr-4">
      
        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#A1D9F7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>


      </button>
      <span class="text-[13px] font-semibold text-[#A1D9F7]">{{ currentMonth.toUpperCase() }} {{ currentYear }}</span>
      <button @click="nextMonth" class="ml-4">
      
        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L8.43043 8.17424C9.18986 8.90341 9.18986 10.0966 8.43043 10.8258L2 17" stroke="#A1D9F7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>

      
      </button>
    </div>
    <table class="mx-auto mt-2">
      <thead class="border-b-2 border-[#A1D9F7]">
        <tr>
          <th class="text-[#A1D9F7] text-lg" width="45px">Пн</th>
          <th class="text-[#A1D9F7]" width="45px">Вт</th>
          <th class="text-[#A1D9F7]" width="45px">Ср</th>
          <th class="text-[#A1D9F7]" width="45px">Чт</th>
          <th class="text-[#A1D9F7]" width="45px">Пт</th>
          <th class="text-[#DE3A73]" width="45px">Сб</th>
          <th class="text-[#DE3A73]" width="45px">Нд</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="week in weeks">
          <td 
            v-for="day in week" 
            :key="day.id" 
            class="text-center text-lg px-1" 
            :class="
              {
                'text-[#DE3A73]' : day.weekDay == 5 || day.weekDay == 6,
                'text-[#A1D9F7]' : day.weekDay != 5 && day.weekDay != 6
              }
            "
            height="40px" width="40px"
          >
            <div 
              @click="emitDate(day)"
              class="rounded-xl bg-opacity-25 h-full align-middle text-center pt-[4px]"
            >
              {{ day.monthDay }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { getWeeksInMonth, addingMatchOfTheDayOfTheWeekAndItsNumberInMonth, formatWeeks } from './service/helpers'

  export default {
    data() {
      return {
        currentMonth: '',
        currentYear: '',
        weeks: [],
        currentDate: new Date()
      }
    },

    created() {
      this.currentDate = new Date()
      this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
      this.currentYear = this.currentDate.getFullYear()
      this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
    },
    methods: {
      previousMonth() {
        this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() - 1)
        this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
        this.currentYear = this.currentDate.getFullYear()
        this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
      },
      nextMonth() {
        this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() + 1)
        this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
        this.currentYear = this.currentDate.getFullYear()
        this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
      },
      emitDate(day) {
        this.$emit('settedDay', day.dayDate)
      }
    }
  }
</script>
