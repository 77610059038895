<template>
    <div>
      <svg :width="width" :height="height">
        <!-- Рисуем точки графика и соединяем их линиями -->
        <polyline
          :points="plotPoints"
          fill="none"
          stroke="#A1D9F7"
          stroke-width="2"
        />
  
        <!-- Добавляем подписи к оси X -->
        <g class="x-axis">
          <text
            v-for="(label, index) in labels"
            :key="index"
            :x="xOffset + index * xStep"
            :y="chartHeight + xAxisOffset + 10"
            text-anchor="middle"
            fill="#A1D9F7"
            font-size="10px"
          >
            {{ label }}
          </text>
        </g>
  
        <!-- Добавляем значения к точкам на графике -->
        <g class="data-points">
          <text
            v-for="(value, index) in data"
            :key="index"
            :x="xOffset + index * xStep"
            :y="chartHeight - value * yStep - dataPointOffset"
            text-anchor="middle"
            fill="#A1D9F7"
            font-size="10px"
          >
            {{ value }}
          </text>
        </g>
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      data: {
        type: Array,
        required: true,
      },
      labels: {
        type: Array,
        required: true,
      },
      width: {
        type: Number,
        default: 350,
      },
      height: {
        type: Number,
        default: 100,
      },
    },
    computed: {
      chartWidth() {
        return this.width - 30; // Учитываем отступы
      },
      chartHeight() {
        return this.height - 30; // Учитываем отступы
      },
      xOffset() {
        return 10; // Отступ слева
      },
      xAxisOffset() {
        return 10; // Отступ над подписями оси X
      },
      yStep() {
        const maxDataValue = Math.max(...this.data) || 1;
        return (this.chartHeight - 20) / maxDataValue;
      },
      xStep() {
        return this.chartWidth / (this.data.length - 1);
      },
      plotPoints() {
        return this.data
          .map((value, index) => `${this.xOffset + index * this.xStep},${(this.chartHeight - 20 - value * this.yStep) + 20 }`)
          .join(' ');
      },
      dataPointOffset() {
        return 10; // Отступ над значениями точек графика
      },
    },
  };
  </script>

  